import "../css/components/Footer.css";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="d-flex flex-column flex-lg-row justify-content-around align-content-center">
        <p className="footerInfo">
          <strong>Dirección:</strong> Avda. Millán 2828
        </p>
        <p className="footerInfo">
          <strong>E-mail:</strong> info@residencialmamina.com.uy
        </p>
        <p className="footerInfo">
          <strong>Teléfono:</strong> 091033033 / 22035962 / 092999999
        </p>
        <div className="d-flex">
          <a href="https://www.instagram.com/residencialdeancianosmamina?igsh=MW1za3h3d2x6MXJwMQ==">
            <img src={instagram} alt="" className="icons" />
          </a>
          <a href="https://www.facebook.com/ResidencialMamina?mibextid=ZbWKwL">
            <img src={facebook} alt="" className="icons" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
